import React, { useEffect, useState } from 'react'
import { getOnlineRegistration } from '../Service/Api';
import LazyLoad from 'react-lazyload';

function OnlineReg() {


    
  const [data, setData] = useState([]);

  useEffect(()=>{
    const getData =async ()=>{
      try {
        let registrationData = await getOnlineRegistration()
        setData(registrationData);

      } catch (error) {
        console.log(error)
      }
    }

    getData()
  },[])


  useEffect(() => {
    console.log("events_data", data);
}, [data]);


return (
    <div style={{ display: data.length > 0 ? 'block' : 'none' }}>
      <div className='online-reg'>
      {data ? ( <img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/online-reg.png"  alt="Tagore Public School, Faridabad"  />):(<></>)} 
     
        
      </div>
    </div>
  );
  
  
}

export default OnlineReg