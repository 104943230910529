import React, { useEffect, useState } from 'react' 
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getGallery } from '../Service/Api';
import { Link } from  'react-router-dom'
import LazyLoad from 'react-lazyload';
const Gallery = () => {
    const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const categories = [
    { name: "Activities", path: "/Activites", placeholder: "about.jpg" },
    { name: "Competitions", path: "/Competition", placeholder: "about.jpg" },
    { name: "Assemblies", path: "/Assemblies", placeholder: "about.jpg" },
    { name: "Achievements", path: "/Achivement", placeholder: "about.jpg" },
    { name: "Celebration", path: "/Celebrations", placeholder: "about.jpg" },
    { name: "Sports", path: "/Sports", placeholder: "about.jpg" }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const galleryData = await getGallery();
        setData(galleryData);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const settings = {
    infinite: true,
    autoplay: true,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 567,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 0,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
};
  const renderCategory = (category) => {
    const categoryData = data.find(item => item.category === category.name);

    return (
  
        <div className="item" key={category.name}>
        <div className="gal-img"> 
           <LazyLoad> <img src={categoryData ? `https://webapi.entab.info/api/image/${categoryData.attachments[0]}`
            : `https://webapi.entab.info/api/image/TAGOREFBD/public/Images/${category.placeholder}`
            }
            className="img-fluid dynamicgalimg"
            /> </LazyLoad>
            <div class="description">
                <Link to={category.path}>
                     <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/zoom.png" /> </LazyLoad>
                </Link>
                <h3>{category.name}</h3>  
            </div> 
        </div>
    </div>
    );
  };
  
 
    return (
        <>
            <div className="gallery-box"> 
            <Slider {...settings}>
                { 
                    categories.map(renderCategory)
                  } 
                </Slider>
            </div>
        </>
    )
}
export default Gallery