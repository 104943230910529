// import $ from 'jquery'
import React, { useRef } from 'react';
import Header from '../components/Header'
import News from '../components/News'
import Copyright from '../components/Copyright'
import SliderComponent from '../components/lightslider'
import Events from '../components/Events'
import PrincipalMessage from '../components/PrincipalMessage' 
import Footer from '../components/Footer'
import { Link, Navigate } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Highlights from '../components/Highlights'
// import Facilities from '../components/Facilities';
import Notification from '../components/Notification'
import Gallery from '../components/Gallery'
import LazyLoad from 'react-lazyload';

//import WOW from 'wow.js'; // Import WOW.js library
const Home = () => {
    const videoRef = useRef(null);

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
            videoRef.current.classList.add('active');
        }
    };
    const handlepause = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.classList.remove('active');
        }
    };

    var settings = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
        dots: true,
        items: 1,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 1,
            },
            1200: {
                items: 1,
            },
            1600: {
                items: 1,
            }
        }
    };



    return (
        <>
<Notification />
            <Header></Header>

            {/* <div className='online-reg'>
                    <img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/registration.png" />
                </div> */}
            <div className='container-fluid min-ht'>
                <div className="row">

                    <div className='col-md-12 pd-0 order-lg-12'>
                        <SliderComponent />
                        <div className='contact-header'>
                            <a href="tel: +91-8527733139">
                                <LazyLoad><img src='https://webapi.entab.info/api/image/TAGOREFBD/public/Images/phone.gif' alt="Tagore Public School Faridabad" /> </LazyLoad> +91-8527733139</a>
                            <a href="mailto: tagoreschoolfbd89@gmail.com">
                                <LazyLoad><img src='https://webapi.entab.info/api/image/TAGOREFBD/public/Images/email.gif' alt="Tagore Public School Faridabad" /> </LazyLoad> tagoreschoolfbd89@gmail.com</a>
                        </div>
                        <div className='social-media'>
                           <Link to="https://www.facebook.com/profile.php?id=61566116601418" target='_blank'> 
                           <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/facebook.png" alt="Tagore Public School Faridabad" /> </LazyLoad></Link>
                           <Link to="https://www.instagram.com/p/DBc13dbI5NP/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" target='_blank'>
                             <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/insta.png" alt="Tagore Public School Faridabad" /></LazyLoad>
                             </Link>
                           <Link to="https://www.blogger.com/profile/18329200257990021173" target='_blank'> 
                           <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/blogger.png" alt="Tagore Public School Faridabad" /></LazyLoad>
                           </Link> 
                        </div>
                        <div className='highlihgt animateMe' data-animation="fadeInTop" >
                <div className='icon'>
                    <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/announcement.gif" alt="Tagore Public School Faridabad"></img></LazyLoad>
                </div>
                <Highlights />
            </div>
                    </div>
                </div>

            </div>
            


            <div class="bg-image">

                <div className="about-us">
                    <div className='container'>
                        <div className='row'>

                            <div className='col-lg-12 col-md-12 animateMe' data-animation="fadeInLeft">
                                <LazyLoad><img  src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/about.png" className='img-fluid' alt="Tagore Public School Faridabad" /></LazyLoad>
                            </div>
                            <div className='col-lg-12 col-md-12 animateMe' data-animation="fadeInRight">
                                <div className="title">
                                    <div>
                                        <h3><span>ABOUT</span> US</h3>
                                    </div>
                                </div>
                                <p>Welcome to Tagore Public School, where excellence in education meets unparalleled opportunities for growth and success. With a legacy of over 50 years, under the visionary guidance of our esteemed Academic Director, Mrs. Manorama Arora, Tagore Public Schools in Faridabad and Palwal stands as a beacon of academic brilliance and holistic development.</p>
                                <p>At present, we are proud to nurture the minds of 6500 students across both campuses. Our proven track record in academic achievements speaks volumes, with our students consistently excelling in CBSE examinations and securing admissions to some of the most prestigious colleges and professional courses. Our results are on par with the best institutions in the NCR, with dozens of our students successfully clearing competitive exams such as NEET, IIT, IIM, and CA entrance exams.</p>
                                {/* <div className='button abt-button'><Link to="/"><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/Read-more.png" /></Link></div> */}

                                <div className='about-virtual'>

                                    <video width="750" height="500" ref={videoRef} controls={false} loop poster="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/video.png"><source src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/virtual.mp4" type="video/mp4" /></video>
                                    <div className='text'>
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/vt.png" alt="Tagore Public School Faridabad" /></LazyLoad>
                                        <h3>Virtual Tour made it <span>Simple</span></h3>
                                        <button onClick={handlePlay}>get started</button>
                                    </div>

                                    <button onClick={handlepause}><i class="bi bi-pause-fill"></i></button>
                                </div>
                                <Link to="/about"><div className="button"><span>View More</span> <div className='img'>
                                     <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/arrow.png" alt="Tagore Public School Faridabad"></img></LazyLoad>
                                     </div></div></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='facilities'>
                <div>
                    <div className="title">
                        <div>
                            <p>TAGORE PUBLIC SCHOOL</p>
                            <h3><span>OUR</span> FACILITIES</h3>
                        </div>
                    </div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-xl-2 col-lg-4 col-md-6 animateMe'  data-animation="fadeInUp">
                                <div className='facil-blk'>
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/faci1.gif"></img></LazyLoad>
                                    <h3>Highly Equipped<br></br> Campus</h3>
                                    <p>We have a highly equipped campus for the students to enhance their skills and develop their personality</p>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-4 col-md-6 animateMe' data-animation="fadeInUp">
                                <div className='facil-blk'>
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/rfid.gif"></img></LazyLoad>
                                    <h3>RFID Enabled<br></br> Campus</h3>
                                    <p>We have a highly equipped campus for the students to enhance their skills and develop their personality</p>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-4 col-md-6 animateMe' data-animation="fadeInUp">
                                <div className='facil-blk'>
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/faci3.gif"></img></LazyLoad>
                                    <h3>Centrally  Air<br></br>Conditioner</h3>
                                    <p>We have a highly equipped campus for the students to enhance their skills and develop their personality</p>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-4 col-md-6 animateMe' data-animation="fadeInUp">
                                <div className='facil-blk'>
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/faci4.gif"></img></LazyLoad>
                                    <h3>Transportation <br></br>Services</h3>
                                    <p>We have a highly equipped campus for the students to enhance their skills and develop their personality</p>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-4 col-md-6 animateMe' data-animation="fadeInUp">
                                <div className='facil-blk'>
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/faci5.gif"></img></LazyLoad>
                                    <h3>AI Integrated <br></br>Campus</h3>
                                    <p>We have a highly equipped campus for the students to enhance their skills and develop their personality</p>
                                </div>
                            </div>
                            <div className='col-xl-2 col-lg-4 col-md-6 animateMe' data-animation="fadeInUp">
                                <div className='facil-blk'>
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/faci6.gif"></img></LazyLoad>
                                    <h3>Greenery with <br></br>Security</h3>
                                    <p>We have a highly equipped campus for the students to enhance their skills and develop their personality</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="imp-link">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4 animateMe'  data-animation="fadeInLeft">
                            <Link to="/Cbse"> <div className='d-flex'>
                                <div className="imp-img">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/CBSE.gif" /></LazyLoad>
                                </div>
                                <h4>CBSE Disclosure</h4>
                            </div>
                            </Link>
                        </div>
                        <div className='col-md-4 animateMe'  data-animation="fadeInUp">
                            <div className='d-flex'>
                                <div className="imp-img">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/doc.gif" /></LazyLoad>
                                </div>
                                <h4>Admission Information</h4>
                            </div>
                        </div>
                        <div className='col-md-4 animateMe' data-animation="fadeInRight">
                            <div className='d-flex'>
                                <div className="imp-img">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/holiday.gif" /></LazyLoad>
                                </div>
                                <h4>List of Holidays & Planner</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='news-event'>
                <div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-4 news-bg animateMe' data-animation="fadeInLeft">

                                <div className='news'>
                                    <div className="title">
                                        <div>
                                            <p>whats happening</p>
                                            <h3><span>latest</span> News</h3>
                                        </div>
                                        <div class="button padding arrow"> <div class="img mar-0"> <Link to="/News">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/arrow.png" /></LazyLoad>
                                            </Link></div></div>
                                    </div>
                                    <div className='news-blk'>
                                        <News />

                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-8 animateMe' data-animation="fadeInRight">

                                <div className='events'>
                                    <div className="title center">
                                        <div>
                                            <p>UPSHORT OF ACTIVITIES</p>
                                            <h3><span>CURRENT</span> EVENTS</h3>
                                        </div>
                                        <div class="button padding arrow"> <div class="img mar-0"><Link to="/Events">
                                             <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/arrow.png" /></LazyLoad>
                                             </Link></div></div>
                                    </div>
                                    <div className='events-blk'>
                                        <Events />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="message">
                <div>
                    <div className='container-fluid'>
                        <div class="row">

                            <div className='col-md-9 animateMe' data-animation="fadeInLeft">
                                <OwlCarousel className='owl-theme message-blk' loop margin={10} {...settings}>
                                    <PrincipalMessage />
                                </OwlCarousel>
                            </div>
                            <div class="col-md-3 col-sm order-sm-1 animateMe" data-animation="fadeInRight">
                                <div className='title'>
                                    <p>
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/email.png" /></LazyLoad> from the desk of</p>
                                    <h3><span>Messege</span> section</h3>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='gallery animateMe' data-animation="fadeInUp">
                <div>
                    <div className='news-event'>
                        <div class="title center">
                            <div>
                                <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/gallery-icon.png" /></LazyLoad>
                                <p>SCHOOL IN THE MEDIA</p>
                                <h3><span>SCHOOL</span>  GALLERY</h3>
                            </div>
                             
                        </div>
                    </div>
                    <Gallery />
                </div>
            </div>
            <Footer />
            <Copyright />
        </>
    )
}
export default Home;