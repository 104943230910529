import React from 'react'
import Header from '../components/Header'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Breadcrumb from '../components/Breadcrumb'
import LazyLoad from 'react-lazyload'
const ContactUs = () => {
    return (
        <>
            <Header />
            {/* <div className="innerslide Admissionbg"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> Contact Us </li>
            </ul>
       </div> */}
            <Breadcrumb mid="Contact" breadcrumbName="Contact Us" />
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="title">
                                <div>
                                    <h3><span>Contact </span> Us</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>                        
                        <div className='col-lg-6'>
                            <div className="contact-container">
                                <div className="contact-blk">
                                    <div className="contact-icon">
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/location1.png" className="img-fluid" alt="Tagore Public School" /></LazyLoad>
                                    </div>
                                    <div className="contact-info">
                                        <h6 className="title">Address</h6>
                                        <p><Link to="https://www.google.com/maps/place/28%C2%B024'53.0%22N+77%C2%B021'46.6%22E/@28.4147403,77.3587337,16z/data=!4m4!3m3!8m2!3d28.4147233!4d77.3629358?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D" target="_blank">
                                            Tagore Public School <br />
                                            Block G, Sector 89, Faridabad</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="contact-blk">
                                    <div className="contact-icon">
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/phone1.png" className="img-fluid" alt="Tagore Public School" /></LazyLoad>
                                    </div>
                                    <div className="contact-info">
                                        <h6 className="title">Phones</h6>
                                        <p><Link to="tel:8527733139">  +91-8527733139</Link>  </p>
                                    </div>
                                </div>
                                <div className="contact-blk">
                                    <div className="contact-icon">
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/mail1.png" className="img-fluid" alt="Tagore Public School" /></LazyLoad>
                                    </div>
                                    <div className="contact-info">
                                        <h6 className="title">Email</h6>
                                        <p><Link to="mailto:tagoreschoolfbd89@gmail.com"> tagoreschoolfbd89@gmail.com</Link> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d7018.299343979231!2d77.3587337!3d28.4147403!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDI0JzUzLjAiTiA3N8KwMjEnNDYuNiJF!5e0!3m2!1sen!2sin!4v1731316369759!5m2!1sen!2sin" width="100%" height="350" style={{ border: " 0;" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>




                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs
