import React, { useEffect, useState } from "react"; 
import { useNavigate, Link } from "react-router-dom";
import { getGalleryInner } from "../Service/Api";  
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";
import LazyLoad from "react-lazyload";
const Assemblies = () => { 
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
      useEffect(() => {
      const fetchData = async () => {
           try{
             const galleryData = await getGalleryInner();
             setData(galleryData);
           }catch (error) {
             console.log("Error fetching gallery data:",error)
           }finally{
             setLoading(false)
           }
        
        };
      fetchData();
      }, []);
    const emptyData = [
       { id: 1}
   ];
  return (
     <>
     <Header />
     <Breadcrumb mid="Gallery" breadcrumbName="Assemblies" />
        <div className="innerSection">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="title">
                            <div>
                                <h3><span> Assemblies</span> </h3>
                            </div>
                        </div>
                        </div>
                    </div>
           
              <div className="row">
              {data.length > 0 ? (data?.filter((item) =>  item.category === "Assemblies").map((item, index) => { 
                   
                  return (
                    <div key={index} className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount">
                    <div className="gal_new">
                      <article>
                        <LazyLoad><img src={`https://webapi.entab.info/api/image?url=${item?.attachments[0]}`} alt="Tagore Public School Faridabad" className="img-fluid" /></LazyLoad>
                        <div className="gal-content">
                          <h4 className="catgry-name">{item.title}</h4> 
                           
                          <div className="total-img"><i className="bi bi-card-image"></i><p>{item?.attachments.length}</p></div>
                          <a onClick={() => navigate(`/SubGallery?id=${item._id}`)} className="view-more">View More <i className="bi bi-arrow-right"></i></a>
                        </div>
                      </article>
                    </div>
                  </div>
                  );
                })
              ) : (
                emptyData.map((data, index) => (
                  <div key={index} className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount">
                        <div className="gal_new">
                          <article>
                            <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/about.jpg" alt="Tagore Public School Faridabad" className="img-fluid" /></LazyLoad>
                            <div className="gal-content">
                              <h4 className="catgry-name">Gallery Title</h4>
                              <p>Stay Tuned for Latest Updates</p>
                              <h6 className="date"><i className="bi bi-calendar-week"></i> DD MM YYYY</h6>
                              <div className="total-img"><i className="bi bi-card-image"></i><p>5</p></div>
                            </div>
                          </article>
                        </div>
                      </div>
                ))
              )}
            </div> 
</div>
</div>
<Footer />
<Copyright />
    </>
  )
}

export default Assemblies
