import React, { useEffect, useState } from 'react';
import { getEvents } from '../Service/Api';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LazyLoad from 'react-lazyload';

const Events = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const datas = await getEvents();
            setData(datas);
        };
        getData();
    }, []);

    const settings = {
        infinite: true,
        autoplay: true,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 567,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <Slider {...settings}>
            {data?.length > 0 &&
                data?.map((item, index) => (
                    <div className="item" key={index}>
                        <div className='evt-blk'>
                            <div className="evt-img">
                                <LazyLoad><img src={`https://webapi.entab.info/api/image/${item?.images}`} alt="Tagore Public School, Faridabad" /></LazyLoad>
                                <p className='date'>
                                    {new Date(item.date).toLocaleString('en-US', { month: 'short' })} <span>{new Date(item.date).getDate()}</span> {new Date(item.date).getFullYear()}
                                </p>
                                <div className='readmore'>READ MORE  
                                <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/arrow.png" alt="Tagore Public School, Faridabad" /></LazyLoad>
                                </div>
                                <div className="evt-title"><h3>{item.title}</h3></div>
                                <p>{item.description}</p>
                            </div>
                            <div className='evt-content'>
                                <p>{item.venue}</p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </Slider>
    );
};

export default Events;
