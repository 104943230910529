import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import  OnlineReg  from '../components/OnlineReg'
import { API_URL, getHeader } from "../config";
import LazyLoad from 'react-lazyload';

export const form = async (formData, header, type) => {
  try {
    console.log('Sending request to:', `${API_URL}/api/form/${formData.schoolcode}`);
    console.log('Form data:', formData);
    console.log('Header:', header);

    const { data } = await axios.post(`${API_URL}/api/form/${formData.schoolcode}/${type}`, formData, { headers: header });
    console.log('Response data:', data);
    return data;
  } catch (error) {
    console.error('Error in form submission:', error);
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
    }
    throw error;
  }
};

const Header = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
    dob: "",
    fatherName: "",
    class: "",
    schoolcode: "TAGOREFBD"
  });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData.message) newErrors.message = "Message is required";
    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    if (!formData.dob) newErrors.dob = "Date of Birth is required";
    if (!formData.fatherName) newErrors.fatherName = "Father's name is required";
    if (!formData.class) newErrors.class = "Class selection is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
        const type = 'Admission';
      const header = getHeader();
      console.log('Submitting form with data:', formData);
      const response = await form(formData, header, type);
      console.log('Form submitted successfully:', response);
      setSubmitted(true);
      setFormData({ name: "", email: "", phone: "", dob: "", fatherName: "", class: "", message: "", schoolcode: formData.schoolcode });
      setErrors({});
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const marqueeRef = useRef(null);

  const handleMouseOver = () => {
    marqueeRef.current.stop();
  };

  const handleMouseOut = () => {
    marqueeRef.current.start();
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth >= 991);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [active, setActive] = useState("");

  const handlePopup = () => {
    setActive("active");
  }

  const handleClose = () => {
    setActive("");
  }

  return (
    <div className="header">
      <div className="header-blk">
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-xl-4 col-lg-12'>
              <Link to="/">
                <div className='logo'>
                  <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/logo.png" className='img-fluid' alt="Tagore Public School, Faridabad"  /></LazyLoad>
                </div>
              </Link>
            </div>
            <div className='col-xl-8 col-lg-12'>
              <div className="contact-tab">
                <div><Link to="/pdf/register-now.pdf" target='_blank'><div className="button"><span>Admission Info </span><div className='img'> 
                <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/icon1.png"  alt="Tagore Public School, Faridabad"  /></LazyLoad>
                </div></div></Link></div>
                {/*  <div><div className="button"><span>parent login</span> <div className='img'> <img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/icon2.png"  alt="Tagore Public School, Faridabad"  /></div></div></div>  */}
                <div className="online-reg" onClick={handlePopup}><OnlineReg /></div>
                <div><div className="button light padding">
                  <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/icon3.png"  alt="Tagore Public School, Faridabad" /></LazyLoad> &nbsp;<span>Blog </span></div></div>
                 {/* <div><div className="button padding"><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/icon4.png" alt="Tagore Public School, Faridabad"  /> &nbsp;<span> Help</span> </div></div>  */}
              </div>
            </div>
            <div className='menu-tab'>
              <button className="menu-toggle" onClick={toggleMenu}>
                <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/menu.png"  alt="Tagore Public School, Faridabad"  /></LazyLoad>
                </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`form-section ${active}`}>
        <div className='form-block'>
          {submitted && <p className="success-message">Thank you for your message!</p>}
          <div className='close' onClick={handleClose}>
            <i className="bi bi-x-lg"></i>
          </div>
          <div className='form-heading'>
            <h3>Register Here</h3>
          </div>
          <form className='form-feilds' onSubmit={handleSubmit}>
            <div>
              <label>Student Name</label>
              <input type="text" placeholder='Student Name' name="name" value={formData.name} onChange={handleChange} />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>
            <div>
              <label>Date Of Birth</label>
              <input type="date" placeholder='DOB' name="dob" value={formData.dob} onChange={handleChange} />
              {errors.dob && <p className="error">{errors.dob}</p>}
            </div>
            <div>
              <label>Phone Number</label>
              <input type="tel" placeholder='Phone Number' name="phone" value={formData.phone} onChange={handleChange} />
              {errors.phone && <p className="error">{errors.phone}</p>}
            </div>
            <div>
              <label>Email Id</label>
              <input type="email" placeholder='Email Id' name="email" value={formData.email} onChange={handleChange} />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div>
              <label>Father Name</label>
              <input type="text" placeholder='Father Name' name="fatherName" value={formData.fatherName} onChange={handleChange} />
              {errors.fatherName && <p className="error">{errors.fatherName}</p>}
            </div>
            <div>
              <label>Select Class</label>
              <select name="class" value={formData.class} onChange={handleChange}>
                <option value="">Select Class</option>
                <option value="1">I</option>
                <option value="2">II</option>
                <option value="3">III</option>
                <option value="4">IV</option>
                <option value="5">V</option>
                <option value="6">VI</option>
                <option value="7">VII</option>
                <option value="8">VIII</option>
                <option value="9">IX</option>
                <option value="10">X</option>
                <option value="11">XI</option>
                <option value="12">XII</option>
              </select>
              {errors.class && <p className="error">{errors.class}</p>}
            </div>
            <div className="message-feild">
              <label>Message</label>
              <textarea placeholder='Enter Your Message' name="message" value={formData.message} onChange={handleChange}></textarea>
             
            </div>
            <div className="submit">
              <input type="submit" value="Submit" />
            </div>
          </form>
        </div>
      </div>
      <div className="menu">
        {isOpen && (
          <div>
            <div className="logo">
              <NavLink to="/">
                <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/logo.png"  alt="Tagore Public School, Faridabad Logo"  /></LazyLoad>
              </NavLink>
            </div>
            <ul className="submenu animate__animated animate__fadeIn animate__delay-1s">
              <li>about
                <ul className="sub-submenu">
                  <li><NavLink to='/about'>About Us</NavLink></li>
                  <li><NavLink to='/vision'>Vision Statement</NavLink></li>
                  <li><NavLink to='/mission'>Mission Statement</NavLink></li>
                  <li><NavLink to="/PrincipalMessage">Principal's Message</NavLink></li>
                  <li><NavLink to="/ChairmanMessage">Chairman's Message</NavLink></li>
                  <li><NavLink to="/pageData">School Information</NavLink></li>
                  <li><NavLink to="/pageData">Activities</NavLink></li>
                </ul>
              </li>
              <li>
                admissions
                <ul className="sub-submenu">
                  <li><NavLink to='/pageData'>Syllabus</NavLink></li>
                  <li><NavLink to='/pageData'>School Timings</NavLink></li>
                  <li><NavLink to='/pageData'>Annual Fee Structure</NavLink></li>
                  <li><NavLink to='/pageData'>Admission Procedure</NavLink></li>
                  <li><NavLink to='/pdf/register-now.pdf'>Admission Information</NavLink></li>
                  <li><NavLink to='/pdf/Admission-Form.pdf'>Admission Form</NavLink></li>
                  <li><NavLink to='/pdf/prospectus.pdf' target='_blank'>Prospectus</NavLink></li>
                </ul>
              </li>
              <li>
                Facilities
                <ul className="sub-submenu">
                  <li><NavLink to='/pdf/nationalLevel.pdf' target='_blank'>National Level Sports Facilities</NavLink></li>
                  <li><NavLink to='/pdf/qualityEdu.pdf' target='_blank'>Affordable Quality Education</NavLink></li>
                  <li><NavLink to='/pdf/holistic.pdf' target='_blank'>Holistic Education</NavLink></li>
                  <li><NavLink to='/pdf/integratedCurr.pdf' target='_blank'>Technology Integrated Curriculum</NavLink></li>
                  <li><NavLink to='/pdf/rfid.pdf' target='_blank'>RFID Tracking Transport Facility</NavLink></li>
                  <li><NavLink to='/pdf/activityInfras.pdf' target='_blank'>Dedicated Activity Based Infrastructure</NavLink></li>
                  <li><NavLink to='/pdf/airConditioner.pdf' target='_blank'>Centrally Air Conditioned</NavLink></li>
                </ul>
              </li>
              <li>
                activities
                <ul className="sub-submenu">
                  <li><NavLink to='/pageData'>Primary Wing </NavLink></li>
                  <li><NavLink to='/pageData'>Clubs and Association</NavLink></li>
                  <li><NavLink to='/pageData'>School Houses</NavLink></li>
                  <li><NavLink to='/pdf/funActivity.pdf' target='_blank'>Fun Filled Activity</NavLink></li>
                </ul>
              </li>
              <li>
                gallery
                <ul className="sub-submenu">
                  <li><NavLink to='/pageData'>Laboratory</NavLink></li>
                  <li><NavLink to='/pageData'>Computer Lab</NavLink></li>
                  <li><NavLink to='/pageData'>Library</NavLink></li>
                </ul>
              </li>
              <li>
                Alumni
                <ul className="sub-submenu">
                  <li><NavLink to='/Gallery'>Photo Gallery</NavLink></li>
                  <li><NavLink to='/VideoGallery'>Video Gallery</NavLink></li>
                </ul>
              </li>
              <li>
                CBSE Disclosure
                <ul className="sub-submenu">
                  <li><NavLink to='/smc'>School Management committee</NavLink></li>
                </ul>
              </li>
              <li>
                contact
                <ul className="sub-submenu">
                  <li><NavLink to='/contactUs'>contact us</NavLink></li>
                </ul>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
