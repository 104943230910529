import React from "react";
import { Link } from 'react-router-dom';
import ContactBranch from '../components/ContactBranch'
import LazyLoad from "react-lazyload";

const Footer = () => {
    return(
        <>
        <footer>
            <div className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-2 col-lg-3">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/ftr-logo.png" alt="Tagore Public School, Faridabad" /></LazyLoad>
                        </div>
                        <div className="col-xl-3 col-lg-9">
                            <div className="quick-link">
                                <h3>Quick Links</h3>
                                <ul>
                                    <li><Link to='/'>Home</Link></li>
                                    <li><Link to='/about'>About Us</Link></li>
                                    <li><a href="/smc">School Management</a></li> 
                                    <li><a href="/PrincipalMessage">Principal’s Desk</a></li>
                                    <li><a href="/Result">Result</a></li> 
                                    <li><a href="/Tc">Transfer Certificate</a></li> 
                                    <li><a href="/Gallery">Picture Gallery</a></li>
                                    <li><a href="/VideoGallery">Video Gallery</a></li>
                                    <li><a href="/">Careers</a></li>
                                    <li><a href="/">Student Login</a></li>
                                    {/* <li><a href="/">Parent Login</a></li>  */}
                                    <li><a href="/">Alumni Login</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2  col-lg-6">
                            <div className="cont-dtl">
                                <h3>Location</h3>
                                <p><a href="https://www.google.com/maps/place/28%C2%B024'53.0%22N+77%C2%B021'46.6%22E/@28.4147403,77.3587337,16z/data=!4m4!3m3!8m2!3d28.4147233!4d77.3629358?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D" target="_blank"><i class="bi bi-geo-alt"></i> <span>Block G, Sector 89, Faridabad</span></a></p>
                                <p><i class="bi bi-telephone-fill"></i> <span><a href="tel: +91-8527733139"> +91-8527733139</a> </span></p>
                                <p><i class="bi bi-globe"></i><span> <a href="www.tps89.in" target="_blank">www.tps89.in</a></span></p>
                                 
                            </div>
                        </div>
                        
                        <div className="col-xl-2 col-lg-6"> 
        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3509.1502361050807!2d77.36036087549286!3d28.414723275784432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDI0JzUzLjAiTiA3N8KwMjEnNDYuNiJF!5e0!3m2!1sen!2sin!4v1731398267466!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        
        </div>
                        <div className="col-xl-3 col-lg-6">
                            <div className="branch">
                                <ContactBranch />
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
            
            </footer>
        </>
    )
}
export default Footer;
