import { useEffect, useState } from 'react'; 

import { Link } from 'react-router-dom'
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";
import {getPrincipalMessage} from'../Service/Api'
import LazyLoad from 'react-lazyload';
const ChairmanMessage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1}
   ];
  return (
     <>
       <Header />
            <Breadcrumb mid="About" breadcrumbName="Chairman's Message" />
                <div className="innerSection">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className='title'>
                        <h3><span>Chairman's</span> Message</h3>
                        </div> 
                    </div>
                </div>
                
                {data?.length > 0 ? data?.filter((div) => div.category === "Chairman").map((item, index) => ( 
                    <div className="clearfix" key={index}>
                     <LazyLoad><img src={`https://webapi.entab.info/api/image/${item.attachments}`} className="img-fluid col-md-4 float-md-end mb-3 msgimg" alt="Tagore Public School" /></LazyLoad>
                
                       <p><div dangerouslySetInnerHTML={{ __html: item.message }} /></p> 
                      <p className="name"> <b><div dangerouslySetInnerHTML={{ __html: item.name }} /></b><span> <div dangerouslySetInnerHTML={{ __html: item.category }} /> </span></p>
              
                 
                </div> 
                )) : emptyData.map((item) => (
                    <div className="clearfix"> 
                    <LazyLoad><img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/chairman.jpg" className="img-fluid col-md-4 float-md-end mb-3 msgimg"  alt="Tagore Public School" /></LazyLoad>
                    <p>I write this message not only as a Chairman but as a beholder of vision,  with a resolute determination to shape our common dream of of Tagore Public School, sector 89.</p><p>
                            I feel inspired to  weave a strong fabric of holistic education, and a vision for a future that resonates with the aspirations of a growing India. Let us embark on a transformative journey, where the classrooms become breeding grounds for innovation and excellence.</p><p>
                            The synergy between technology and education is profound. Blending our cultural heritage with  scientific advancements shall be the cornerstone of our mission.</p><p>
                            Education must be more than mere transmission of knowledge. We aspire to foster an environment where collaboration thrives,  creativity is boundless, and critical thinking shapes pupils' vision.
                        Let us march forward forging a destiny that shall make Tagore Public School the epitome of evolving brilliance.</p>
                        <p className="name"> <b> Sudesh Gupta</b><br></br><span> Chairman </span></p>
                    </div> 
                    ))}
             </div>
             
        </div> 
       <Footer/>
       <Copyright />
     </>
  )
}

export default ChairmanMessage
